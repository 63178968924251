body {
	margin: 0;
	padding: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #222222;
	font-size: 62.5%;
	-webkit-text-size-adjust: 100%;
}

h1 {
	color: #222222;
	font-size: 2rem;
	font-weight: 300;
	margin-top: 3rem;
	margin-bottom: 0px;
	text-align: center;
}

h2 {
	color: #888888;
	font-size: 1.2rem;
	font-weight: 400;
	margin-top: 0;
	margin-bottom: 2.4rem;
	text-align: center;
}

/* Beta Tag */

span.beta {
	font-size: 1rem;
	color: #fff;
	background: #FB2E35;
	padding: 0.25rem 0.5rem;
	margin-left: 0.25rem;
	box-shadow: 0 1px 2px #888;
	border-radius: 4px;
	font-weight: 500;
	vertical-align: super;
}

/* Address and Avatars */

.ethAddress {
/* 	font-size: 0.875rem; */
	display: inline-block;
	width: 500px;
}

.ethAddress canvas,
.ethAddress img.avatar {
	object-fit: cover;
	border-radius: 5px;
	margin-right: 0.5rem;
	vertical-align: middle;
	width: 24px;
	height: 24px;
}

span.account {
	vertical-align: middle;
}

/* Tooltip */

.__react_component_tooltip {
	padding: 0.5rem 1.125rem !important;
	width: 230px !important;
	opacity: 1 !important;
	background-color: #222 !important;
	text-align: center !important;
/* 	border-top: 8px solid white; */
}

.__react_component_tooltip.place-top {
	margin-top: 2px !important;
}

.__react_component_tooltip.place-bottom {
	margin-top: 0 !important;
}

.__react_component_tooltip.type-dark.place-top:after,
.__react_component_tooltip.type-dark.place-bottom:after {
	border-bottom-color: #222 !important;
}

.__react_component_tooltip.place-top:after,
.__react_component_tooltip.place-bottom:after {
	left: 24px;
}

.__react_component_tooltip p {
	padding: 0 !important;
	margin: 0 !important;
	text-align: left !important;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
}

.__react_component_tooltip div.tooltip-header {
	padding-top: 0.25rem;
}

.__react_component_tooltip div.tooltip-avatar {
	margin: 0 auto;
	border-radius: 999px;
	overflow: hidden;
	width: 96px;
	height: 96px;
	background-color: #EEE;
}

.__react_component_tooltip div.tooltip-avatar canvas,
.__react_component_tooltip div.tooltip-avatar img {
	object-fit: cover;
	border-radius: 999px;
	width: 100%;
	height: 100%;
}

.__react_component_tooltip div.tooltip-header p {
	font-size: 1rem;
	font-weight: 700;
	padding-top: 0.5rem !important;
	padding-bottom: 0.75rem !important;
	text-align: center !important;
}

.__react_component_tooltip div.tooltip-body {
	margin: 0 auto !important;
	margin-left: -0.5rem !important;
	display: inline-block;
}

.__react_component_tooltip div.tooltip-footer {
	font-size: 0.7rem;
	padding-top: 0.25rem !important;
/* 	padding-bottom: 0.25rem !important; */
	text-align: center !important;
	border-top: 1px solid #444;
}

.__react_component_tooltip div.tooltip-body p:last-child {
	padding-bottom: 0.75rem !important;
}

.__react_component_tooltip a {
	color: white;
}

.container {
	margin: 0 auto;
	min-width: 300px;
	max-width: 900px;
}

.header-container {
	width: 100%;
	border-bottom: 1px solid #DEDEDE;
	height: 3rem;
}

.header-center {
	margin: 0 auto;
	width: 96%;
	min-width: 300px;
	max-width: 900px;
	display: flex;
	flex-direction: row;
	align-content: center;
	justify-content: space-between;
}

.header-logo {
	max-height: 2rem;
	margin: 0.5rem 0;
}

.header-button {
	text-decoration: none;
	margin-left: 2rem;
	transition:opacity .2s;
	font-size: 1rem;
	line-height: 3rem;
}

.header-button:hover {
	cursor: pointer
}

.header-button {
	background:#fdbba6;
	background:linear-gradient(135deg,#fdbba6,#ab6a6a 99%);
	filter:progid:DXImageTransform.Microsoft.gradient(startColorstr="#fdbba6",endColorstr="#ab6a6a",GradientType=1);
	border-radius: 999px;
	padding: 0.5rem 0.85rem;
	border-bottom: 1px solid #944d47;
	color: #fff;
	transition:-webkit-filter .2s;
	transition:filter .2s;
	transition:filter .2s, -webkit-filter .2s;
	transition:filter .2s,-webkit-filter .2s
}

.header-button:hover { 
	-webkit-filter:brightness(1.15);
	filter:brightness(1.15)
}

.group {
	padding: 0 1rem;
	margin: auto;
	display: flex;
	align-items: center;
	flex-direction: row-reverse;
	flex-wrap: wrap;
	justify-content: center;
}

.buttons {
	display: flex;
	flex-direction: row;
 	border: 1px solid #DEDEDE;
 	font-size: 0px;
 	border-radius: 5px;
 	padding: 0;
}

.buttons button {
	margin: 0;
	padding: 0.75rem 1rem;
	font-size: 0.875rem;
	font-weight: 500;
	color: #444;
	background-color: #FFF;
	cursor: pointer;
	border: none;
	border-right: 1px solid #DEDEDE;
        transition: 0.1s ease;
	outline: none;
	flex-grow: 0;
}

.buttons button:hover {
	background-color: #F2F2F2;
}

.buttons button.active {
	background-color: #DEDEDE;
}

.buttons button:first-of-type {
	border-radius: 5px 0 0 5px;
}

.buttons button:last-of-type {
	border-radius: 0 5px 5px 0;
	border-right: none;
}


.group:after {
	content: "";
	clear: both;
	display: table;
}

.table {
	margin: 0 auto;
	margin-top: 2rem;
	padding: 0 1rem;
}

.search {
	margin-right: 3em;
	flex-grow: 100;
	max-width: 600px;
	font-size: 1rem;
}

.search input[type="search"] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border: 1px solid #DEDEDE;
	border-radius: 5px;
	color: #222;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 0.875em;
	font-weight: 400;
	outline: none;
	padding: 0.75rem 1rem;
	width: 100%;
}

.search input[type="search"]::-webkit-input-placeholder {
	color: #888;
}

.search input[type="search"]::-ms-input-placeholder {
	color: #888;
}

.search input[type="search"]::placeholder {
	color: #888;
}

/* Overide ReactTable styles */

.ReactTable .rt-thead.-header { 
	padding: 0.25rem 0 !important;
}

.ReactTable .rt-th { /* TABLE HEADER */
	border: none !important;
	color: #888888;
	font-size: 0.7rem;
	font-weight: 700;
	letter-spacing: -0.5px;
	padding: 0.5rem !important;
	text-align: right;
	text-transform: uppercase;
}

.ReactTable .rt-th.center {
	text-align: center
}

span.padding-left {
	padding-left: 2rem;
}

.ReactTable .rt-tr { /* TABLE ROW */
	align-items: center;
}

.ReactTable .rt-td { /* TABLE CELL */
	border: none !important;
	padding: 0.5rem !important;
	line-height: 1.25rem;
	font-size: 0.875rem;
}

.ReactTable .rt-tfoot { 
	padding: 0.25rem 0 0.5rem 0 !important;
	font-weight: 700;
	box-shadow: none !important;
}

.ReactTable {
	border: none !important;
}

.ReactTable .rt-thead.-header {
	box-shadow: none !important;
	border-bottom: 1px solid #DEDEDE !important;
}

.ReactTable .rt-tbody .rt-tr-group {
	border-bottom: 1px solid #DEDEDE !important;
}

.ReactTable .-pagination  {
	box-shadow: none !important;
	border-top: none !important;
}

.ReactTable .rt-thead .rt-th.-sort-asc {
	box-shadow: none !important;
}

.ReactTable .rt-thead .rt-th.-sort-desc {
	box-shadow: none !important;
}

.ReactTable .rt-thead .rt-th.-sort-asc div:first-child:after {
	content: " \25B2";
}

.ReactTable .rt-thead .rt-th.-sort-desc div:first-child:after {
	content: " \25BC";
}

.ReactTable div.-pagination {
	margin: auto;
	margin-top: 1.5rem;
	max-width: 350px;
	font-size: 0.875rem;
	color: #444 !important;
}

.ReactTable .-pagination .-btn {
	background: #FFF !important;
	border-radius: 5px !important;
	color: #444 !important;
}

.ReactTable .-pagination .-btn:not([disabled]):hover {
	background: #F2F2F2 !important;
}


.ReactTable .-pagination .-btn[disabled] {
	background: none !important;
}

.ReactTable .-pagination .-pageJump input {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border: 1px solid #DEDEDE;
	border-radius: 4px;
	outline: none;
	width: 50px !important;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

p.footer {
	text-align: center;
	font-style: italic;
	margin: 1rem 0 2rem 0;
	font-size: 0.7rem;
}


/* Media queries for mobile styles */

@media screen and (max-width: 374px) {
	body {
		font-size: 0.75rem;
	}

	h1 {
		font-size: 1.25rem;
		margin-top: 1.25rem;
	}
  	
  	h2 {
		font-size: 0.875rem;	
		margin-bottom: 1.25rem;
	}
	
	span.beta {
		font-size: 0.75rem;
		padding: 0.25rem;
	}
	
	.buttons button {
		font-size: 0.75rem;
		padding: 0.5rem;
	}
	
	.group {
		padding: 0 0.5rem;
	}
	
	.table {
		margin-top: 1.25rem;
		padding: 0 0.5rem;
	}
	
	.header-links a {
		margin-left: 1rem;
	}

	.ReactTable .rt-th { /* HEADER */
		padding: 0.25rem !important;
	}

	.ReactTable .rt-td { /* CELL */
		padding: 0.25rem !important;
		line-height: 1rem;
	}
	
	.search {
		margin: auto;
		margin-top: 1.5rem;
	}
	
	.search input[type="search"] {
		font-size: 0.75em;
		padding: 0.5rem;
	}
}

@media screen and (max-width: 600px) and (min-width: 375px) {
	h1 {
		font-size: 1.625rem;
		margin-top: 1.25rem;
	}
  	
  	h2 {
		font-size: 0.875rem;
		margin-bottom: 1.5rem;
	}
	
	span.beta {
		font-size: 0.75rem;
		padding: 0.25rem;
	}
	
	.buttons button {
		font-size: 0.875rem;
		padding: 0.5rem;
	}
	
	.table {
		margin-top: 1.5rem;
		padding: 0 0.5rem;
	}

	.ReactTable .rt-td { /* CELL */
		padding: 0.5em !important;
		line-height: 1em;
	}
	
	.search {
		margin: auto;
		margin-top: 1.5rem;
		width: 100%;
	}
	
	.group {
		padding: 0 0.5rem;
	}
	
	.search input[type="search"] {
		font-size: 0.875em;
		padding: 0.5rem;
	}
}
